import React, { useState, useEffect } from 'react';
import './ViewDatenschutz.css';
import {delete_all_cookies} from '../Cookies/cookieFunctions';

const FirstSiteViewDatenschutz = props => {

  //Zustände der Checkboxen setzen
  const [checkEssential, setCheckEssential] = useState (true);
  const [checkStatistics, setCheckStatistics] = useState (false);
  const [checkStatisticsGA, setCheckStatisticsGA]     = useState (false);
  const [save, setSave] = useState(false);
  const isInitialMount = React.useRef(true); //https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
  const {
    onSaveSettings,
    onSettings,
    websiteName,
    pathDatenschutz,
    pathImpressum
  }= props;
  
  React.useEffect(()=>{
    if(isInitialMount.current){
      isInitialMount.current = false;
    }else{
      onSaveSettings({checkEssential, checkStatistics, checkStatisticsGA});
    }
  },[save])

  useEffect(()=>{
    checkStatistics ? setCheckStatisticsGA(true) : setCheckStatisticsGA(false);
  }, [checkStatistics])

  function clickAcceptAllHandler() {
      setCheckEssential(true);
      setCheckStatistics(true);
      setCheckStatisticsGA(true);
      setSave(true);
  }

  const clickDeleteAllCookiesHandler = () => {
    async function del(){
      delete_all_cookies();
    }
    del().then(console.info('Alle Cookies gelöscht'));

  }

  return (
    <div className='container-overview'>
          <h1>DATENSCHUTZEINSTELLUNGEN</h1>
          <p>Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.</p>
          <div className={'chb-container'}>
            <p className={'rbtn-direction-row'}>
            <label htmlFor={'checkbox-statistics'} className={'chb-label'}>
              Essenziell
            </label>
            <input checked disabled id='checkbox-statistics' type='checkbox' onChange = { event => setCheckEssential(event.target.checked)} />
            </p>
            <p className={'rbtn-direction-row'}>
            <label htmlFor={'checkbox-statistics'} className={'chb-label'}>
              Statistiken
            </label>
            <input id='checkbox-statistics' type='checkbox' onChange={event => setCheckStatistics(event.target.checked)} />
            </p>
          </div>
            <p>
            <button
            className={'rbtn rbtn-dark rbtn-format-btn'}
            onClick={()=>clickAcceptAllHandler()}
            >
              Alle akzeptieren
            </button>
            </p>
            <p>
            <button
            to={'/'}
            className={'rbtn rbtn-light rbtn-format-btn'}
            onClick={()=>setSave(true)}
            >
              Speichern
            </button>
            </p>
            <div>
              <button
              className={'rbtn rbtn-dark-link rbtn-format-link'}
              onClick={onSettings}>
                Individuelle Datenschutzeinstellungen
              </button>
              <button
              className={'rbtn rbtn-light-link rbtn-format-link'}
              onClick={()=>clickDeleteAllCookiesHandler()}>
                Alle Cookies von {websiteName} löschen
              </button>
              <div className='rbtn-direction-row rbtn-justify-center'>
                <a
                href={pathImpressum}
                className={'rbtn rbtn-light-link'}>
                  Impressum
                </a>
                <a
                href={pathDatenschutz}
                className={'rbtn rbtn-light-link'}>
                  Datenschutz
                </a>
              </div>
            </div>
      </div>
  );
}

export default FirstSiteViewDatenschutz;
