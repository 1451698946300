import * as google from './googleFunctions';
import { delete_cookies_byName_Array, delete_cookie_byName } from './cookieFunctions';
import { gaCookies } from './settingsCookies';

export function checkOpt(consents, gtmProperty, gaProperty, domain){
    const {
        checkStatisticsGA
    } = consents;

    if(checkStatisticsGA){
        google.gaOptin(gaProperty);
        google.setGtm(window, document, 'script', 'dataLayer', gtmProperty) //hier wird das script vom Google Tag Manager gesetzt
        console.info('Tracking erlaubt.')
    }
    if(checkStatisticsGA === false ){
        google.gaOptout(gaProperty);
        delete_cookies_byName_Array(gaCookies, domain);
        delete_cookie_byName(`_gat_${gaProperty}`);
        console.info('Tracking verboten.')
    }
}