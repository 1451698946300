//** hier werden alle Cookie-Namen exportiert, die von Drittanbietern erzeugt werden */ 

export const gaCookies = [
    '_ga',
    '_gid',
    '_gat',
    'AMP_TOKEN',
    '__utma',
    '__utmt',
    '__utmb',
    '__utmc',
    '__utmz',
    '__utmv',
    '_gaexp',
    '_opt_awcid',
    '_opt_awmid',
    '_opt_awgid',
    '_opt_awkid',
    '_opt_utmc'
]

export const HCOOKIE_NAME                               = 'hammando-cookie';
export const HCOOKIE_LIVETIME                           = '365';

export const HCOOKIE_CONSENT_ESSENTIAL                  = '##1#essential#1##';
export const HCOOKIE_CONSENT_ESSENTIAL_NUM              = '1';

export const HCOOKIE_CONSENT_STATISTICS                 = '##2#statistics#2##';
export const HCOOKIE_CONSENT_STATISTICS_NUM             = '2';
export const HCOOKIE_CONSENT_STATISTICS_GAnalytics      = '#12#google-analytics#12#';
export const HCOOKIE_CONSENT_STATISTICS_GAnalytics_NUM  = '12';

export const HCOOKIE_CONSENT_MEDIA                      = '##3#media#3##';
export const HCOOKIE_CONSENT_MEDIA_NUM                  = '3';
export const HCOOKIE_CONSENT_MEDIA_FACEBOOK             = '#13#facebook#13#';
export const HCOOKIE_CONSENT_MEDIA_FACEBOOK_NUM         = '13';
export const HCOOKIE_CONSENT_MEDIA_GMAPS                = '#23#google-maps#23#';
export const HCOOKIE_CONSENT_MEDIA_GMAPS_NUM            = '23';