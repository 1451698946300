import React from 'react';
import './InfoItem.css';
import './ToggleSwitch.css';

const InfoItem = props => {

    const {
        title, //Titel der InfoItems
        onSwitch, //Funktion gibt boolean zurück
        checkSwitch, //true, wenns CHECKED sein soll
        disableSwitch, //true, wenns DISABLED sein soll
        description, //Text zur, um Titel zu beschreiben
        detailBtnClicked, //Funktion wird ausgeführt, wenn der Detail-Button isCLicked und übergibt auch den Titel
        link, //Text für Detail Button
        children //JEder CHild ist ein Cookie in Detail
    } = props;

    return(
        <div className={"item-container"}>
            <div className={"flex-row-container"}>
                <div className={"item-title"}>{title}</div>
                <div>
                    <label className="switch" onChange={e=>onSwitch(e.target.checked)}>
                        <input type="checkbox" checked={checkSwitch} disabled={disableSwitch} readOnly/>
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>

            <div>{description}</div>
            <div className={"center"}><button onClick={()=>detailBtnClicked(title)}>{link}</button></div>

            { children }

        </div>
    )
}
export default InfoItem;