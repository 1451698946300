import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './ErrorBoundary';

const majorRelease = '1';
const minorRelease = '2';
const patchLevel   = '2';
const buildNumber  = '0044';

export const versionNumber = `${majorRelease}.${minorRelease}.${patchLevel}.${buildNumber}`;

ReactDOM.render(
    <React.Fragment>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </React.Fragment>, document.getElementById('hammando-cookie-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
