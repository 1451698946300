import React, { useState, useEffect, useRef } from 'react';
import logHammandoInfo from './logHammandoInfo';

import CookieOverview from './ViewDatenschutz/FirstSiteDatenschutz';
import CookieSettings from './ViewDatenschutz/SecondSiteDatenschutz';
import GoBackButton from './ViewDatenschutz/GoBackButton';

import {cookie_indexOf, create_cookie, getCookieConsents} from './Cookies/cookieFunctions';
import {checkOpt} from './Cookies/checkOpt';

import { 
    HCOOKIE_NAME,
    HCOOKIE_LIVETIME,
    HCOOKIE_CONSENT_ESSENTIAL,
    HCOOKIE_CONSENT_STATISTICS,
    HCOOKIE_CONSENT_STATISTICS_GAnalytics
} from './Cookies/settingsCookies';

require('dotenv').config(); // https://kiranvj.com/blog/blog/2019/09/01/react-environment-variables-not-working/

function App(){

    //** Cookie Config aus .env */
const {
    REACT_APP_GTM_PROPERTY,
    REACT_APP_WEBSITE_NAME,
    REACT_APP_GA_PROPERTY,
    REACT_APP_WEBSITE_PATH_DATENSCHUTZ,
    REACT_APP_WEBSITE_PATH_IMPRESSUM,
    REACT_APP_WEBSITE_DOMAIN
} = process.env;

 const [view, setView]          = useState("OVERVIEW");
 const [setting, setSetting]    = useState({});
 const isInitialMount           = useRef(true); //https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
 const isCookie                 = useRef(cookie_indexOf(HCOOKIE_NAME));
 const cookieConsents           = useRef({});
 const locationPathname         = useRef(window.location.pathname);

 //***Check if Cookie */
 useEffect(()=> {
    logHammandoInfo();
    cookieConsents.current = getCookieConsents();//in cookieConsents werden die settings reingeladen - entwerder aus dem vorhandenen cookie oder alle Settings sind false;
    if(isCookie.current > -1){ //wenn >-1, dann existiert ein Cookie
        setView("READY");     
    }
    else if(locationPathname.current === REACT_APP_WEBSITE_PATH_DATENSCHUTZ || locationPathname.current === REACT_APP_WEBSITE_PATH_IMPRESSUM ){
        setView("READY")
    }else{
        setView("OVERVIEW")
    }
 }, [isCookie])

//***POST */
 useEffect(()=>{
     if(isInitialMount.current){
         isInitialMount.current = false;
     }else {
        createCookie();
        //**** HIER DIE SETTINGS IN EINE DATENBANK FETCHEN */
     }
 }, [setting]) 

 useEffect(()=>{
    checkOpt(cookieConsents.current, REACT_APP_GTM_PROPERTY, REACT_APP_GA_PROPERTY, REACT_APP_WEBSITE_DOMAIN); //hier wird 
},[setting])

 function saveSettings(settings){
     setSetting(settings); //setting werden gespeichert 
     cookieConsents.current = settings; //hier werden die settings ins ref-Hook kopiert
     setView("READY");
 }

 function createCookie(){
    let strESSENTIAL, 
        strSTATISTIC, 
        strCOOKIE_VALUE,
        strSTATISTIC_GAnalytics    = '';
    
    strESSENTIAL  = setting.checkEssential ? strESSENTIAL = HCOOKIE_CONSENT_ESSENTIAL : ''
    strSTATISTIC  = setting.checkStatistics ? strSTATISTIC = HCOOKIE_CONSENT_STATISTICS :'';
    strSTATISTIC_GAnalytics = setting.checkStatisticsGA ? strSTATISTIC_GAnalytics = HCOOKIE_CONSENT_STATISTICS_GAnalytics : '';

    strCOOKIE_VALUE  = `${strESSENTIAL}${strSTATISTIC}${strSTATISTIC_GAnalytics}`;

    create_cookie(HCOOKIE_NAME,strCOOKIE_VALUE, HCOOKIE_LIVETIME);
 }

 function getView (){
    if ( view === "OVERVIEW"){
        return <CookieOverview onSettings={()=> setView("SETTINGS")} onSaveSettings={saveSettings} websiteName={REACT_APP_WEBSITE_NAME} pathDatenschutz={REACT_APP_WEBSITE_PATH_DATENSCHUTZ} pathImpressum={REACT_APP_WEBSITE_PATH_IMPRESSUM}/>
    }
    else if ( view === "SETTINGS"){
        return <CookieSettings onOverview={()=> setView("OVERVIEW")} onSaveSettings={saveSettings} hammandoCookieLifetime={HCOOKIE_LIVETIME}/>
    }
   
    return (<div>kein View</div>)
 }

 if( view === "READY"){
     return <GoBackButton onOverview={()=>setView("OVERVIEW")} />
 }else{
    return(
        <div className={'ds-container-position-bottom ds-container-background-night'}>
            <div className='ds-container'>
                <a id="hammando-cookie" href={'https://www.hammando.de'} target='_blank'>
                    <div className='hammando-cookie'>
                        <div>POWERED BY HAMMANDO</div>
                    </div>
                </a>
                {getView()}
            </div>
        </div>
     )
 }

 
}
export default App;