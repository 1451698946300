import React, {useState, useEffect, useRef} from 'react';
import './ViewDatenschutz.css';
import InfoItem from './utils/InfoItem';
import InfoItemDetail from './utils/InfoItemDetail';

const btnStyle= {
    width: '50%'
}

const SecondSiteDatenschutz = props => {
    const [save, setSave]                               = useState(false);
    const isInitialMount                                = useRef(true);

    const [checkEssential, setCheckEssential]           = useState (true);
    const [checkStatistics, setCheckStatistics]         = useState (false);

    const [checkStatisticsGA, setCheckStatisticsGA]     = useState (false);

    const [isClickedOnDetails, setIsClickedOnDetails]   = useState(false);
    const clickOnDetailsTitle                           = useRef('');

    useEffect(()=>{
        if(isInitialMount.current){
          isInitialMount.current = false;
        }else{
          props.onSaveSettings({checkEssential, 
                                checkStatistics, 
                                checkStatisticsGA,
                            });
        }
      },[save])

      useEffect(()=> {
        checkStatisticsGA ? setCheckStatistics(true) : setCheckStatistics(false);
      }, [checkStatisticsGA])

      useEffect(()=>{
        checkStatistics ? setCheckStatisticsGA(true) : setCheckStatisticsGA(false);

      }, [checkStatistics])

    function toolgeIsClickedOnDetails(title){
        clickOnDetailsTitle.current = title;
        setIsClickedOnDetails(!isClickedOnDetails);
    }

    return(
        <div className='container-settings'>

                <h1>DATENSCHUTZEINSTELLUNGEN</h1>
                <div><p>
                        {'Hier finden Sie eine Übersicht über alle verwendeten Cookies. Sie können Ihre Zustimmung zu ganzen Kategorien geben oder sich weitere Informationen anzeigen lassen und so nur bestimmte Cookies auswählen.'}
                </p></div>
                <div className={'space-between'}>
                    <button
                    to={'/'}
                    className={'rbtn rbtn-light rbtn-format-btn'}
                    style={btnStyle}
                    onClick={()=>setSave(true)}
                    >
                    Speichern
                    </button>
                    <button
                    className={'rbtn rbtn-dark-link rbtn-format-link'}
                    onClick={props.onOverview}>
                        zurück
                    </button>
                </div>

            <div>
                <InfoItem
                title={'Essenziell'}
                description={'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.'}
                link={'Cookie-Information anzeigen'}
                onSwitch={setCheckEssential}
                checkSwitch={true}
                disableSwitch={true}
                detailBtnClicked={toolgeIsClickedOnDetails}>
                    { 
                        isClickedOnDetails && clickOnDetailsTitle.current === 'Essenziell' ? 
                        <InfoItemDetail
                            checkSwitch                 = {true}
                            disableSwitch               = {true}
                            detailCookieAnbieterName    = {'Hammando Cookie'}
                            detailCookieAnbieter        = {'Eigentümer dieser Website'}
                            detailCookieZweck           = {'Speichert die Einstellungen der Besucher, die in den Datenschutzeinstellungen ausgewählt wurden'}
                            detailCookieName            = {'hammando-cookie'}
                            detailCookieLaufzeit        = {`${(props.hammandoCookieLifetime / 365)} Jahre`}
                        /> 
                        : <> </>
                    }
                </InfoItem>
                <InfoItem
                title={'Statistik'}
                description={'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.'}
                link={'Cookie-Information anzeigen'}
                onSwitch={setCheckStatistics}
                checkSwitch={checkStatistics}
                detailBtnClicked={toolgeIsClickedOnDetails}>
                    { 
                        isClickedOnDetails && clickOnDetailsTitle.current === 'Statistik' ? 
                        <>
                        <InfoItemDetail
                            onSwitch                    = {setCheckStatisticsGA}
                            checkSwitch                 = {checkStatisticsGA} 
                            detailCookieAnbieterName    = {'Google Analytics'}
                            detailCookieAnbieter        = {'Google LLC'}
                            detailCookieZweck           = {'Cookie von Google für Website-Analysen. Erzeugt statistische Daten darüber, wie der Besucher die Website nutzt'}
                            detailCookieName            = {'_ga,_gat,_gid'}
                            detailCookieLaufzeit        = {'2 Jahre'}
                        /> 
                        </> 
                        : <> </>
                    }
                </InfoItem>
            </div>
        </div>
    )
}
export default SecondSiteDatenschutz;