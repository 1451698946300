export function setGtm(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
}

export function gaOptout(gaProperty){
    let disableStr = `ga-disable-${gaProperty}`;
    document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
    window[disableStr] = true
}

export function gaOptin(gaProperty){
    let disableStr = `ga-disable-${gaProperty}`;
    document.cookie = `${disableStr}=;max-age=0`;
    window[disableStr] = false; 
}