import React from 'react';

function GoBackButton (props){

    const {
        onOverview
    } = props;

    return(
        <div id='gobackbutton' className='ds-container-position-bottom pos-right'>
                <button onClick={onOverview}>
                    Datenschutzeinstellungen
                </button>
        </div>
    )

}
export default GoBackButton;