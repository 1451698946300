import * as HCOOKIE from './Cookies/settingsCookies';
import {versionNumber} from './index'

const {
    REACT_APP_GTM_PROPERTY,
    REACT_APP_WEBSITE_NAME,
    REACT_APP_GA_PROPERTY,
    REACT_APP_WEBSITE_PATH_DATENSCHUTZ,
    REACT_APP_WEBSITE_PATH_IMPRESSUM,
    REACT_APP_WEBSITE_DOMAIN
} = process.env

function CONSOLEhammandoCookieInfo(){
    console.info(
        'hammando-cookie Version:',
        versionNumber
    )
}

function CONSOLEcustomerInfo(){
    console.info('Customer:', REACT_APP_WEBSITE_NAME);
    console.info('Customer Domain:', REACT_APP_WEBSITE_DOMAIN);
    console.info('GTM Property:', REACT_APP_GTM_PROPERTY);
    console.info('GA Property:', REACT_APP_GA_PROPERTY);
    
}

export default function logHammandoInfo(){
    console.info('************hammando-cookie Info-Logs********')
    CONSOLEhammandoCookieInfo();
    CONSOLEcustomerInfo();
    console.info('*********************************************')
}

window.hammandoCookie_ProdInfo = CONSOLEhammandoCookieInfo;
window.hammandoCookie_CustomerInfo = CONSOLEcustomerInfo;
window.hammandoCookie_settingsCookie = HCOOKIE;