import React from 'react';
import './InfoItem.css';
import './ToggleSwitch.css';

function InfoItemDetail(props){

    const {
        detailCookieAnbieterName,
        detailCookieAnbieter,
        detailCookieZweck,
        detailCookieName,
        detailCookieLaufzeit,
        onSwitch,
        checkSwitch,
        disableSwitch
    } = props;

    return(
        <div>
            <div>
                <label className="switch" onChange={e=>onSwitch(e.target.checked)}>
                    <input type="checkbox" checked={checkSwitch} disabled={disableSwitch} readOnly/>
                    <span className="slider round"></span>
                </label>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td>{detailCookieAnbieterName}</td>
                    </tr>
                    <tr>
                        <th>Anbieter</th>
                        <td>{detailCookieAnbieter}</td>
                    </tr>
                    <tr>
                        <th>Zweck</th>
                        <td>{detailCookieZweck}</td>
                    </tr>
                    <tr>
                        <th>Cookie Name</th>
                        <td>{detailCookieName}</td>
                    </tr>
                    <tr>
                        <th>Cookie Laufzeit</th>
                        <td>{detailCookieLaufzeit}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default InfoItemDetail;