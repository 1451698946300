export const create_cookie = (NAME, VALUE, DAYtoLIVE) => {
    let DATUM = new Date ();
    DATUM.setTime(DATUM.getTime() + DAYtoLIVE * 24 * 60 * 60 * 1000)
    let DELETEstr = `expires=${DATUM}`;

    document.cookie= `${NAME}=${VALUE};${DELETEstr}`
}

export const read_all_cookies = () => {
    let COOKIE_ARRAY    = document.cookie.split(';');
    let COOKIE_TEMP_ARRAY = [];

    COOKIE_ARRAY.forEach(cookie => {
        while (cookie.charAt(0) === ' '){
            cookie = cookie.substring(1);
        }
        COOKIE_TEMP_ARRAY.push(cookie);
    });

    return COOKIE_TEMP_ARRAY;
}

export const delete_all_cookies = () => {
    let COOKIE_ARRAY = document.cookie.split(';');

    COOKIE_ARRAY.forEach(cookie => {
        let eqPos = cookie.indexOf('=');
        let cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        document.cookie = `${cookieName}=;max-age=0`;
    })
}

export function delete_cookie_byName(name, domain){
    document.cookie = `${name}=;max-age=0;domain=${domain}`;
}

export function delete_cookies_byName_Array(nameArray, domain){
    nameArray.forEach(name => document.cookie = `${name}=;max-age=0;domain=${domain}`)
}

export function cookie_indexOf(name){
    let COOKIE_ARRAY = document.cookie.split(';');
    let COOKIE_NAME_ARRAY = [];

    COOKIE_ARRAY.forEach(cookie => {
        let eqPos = cookie.indexOf('=');
        let cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        while (cookieName.charAt(0) === ' '){
            cookieName = cookieName.substring(1);
        }
        COOKIE_NAME_ARRAY.push(cookieName);
    });

    let namePos = COOKIE_NAME_ARRAY.indexOf(name);

    return namePos;
}

export function read_cookie_byName(name){
    let COOKIE_ARRAY = document.cookie.split(';');
    let COOKIE_ARRAY_FORMATED = [];
    COOKIE_ARRAY.forEach(cookie => {
        while (cookie.charAt(0) === ' '){
            cookie = cookie.substring(1);
        }
        COOKIE_ARRAY_FORMATED.push(cookie);
    });
    let cookie = COOKIE_ARRAY_FORMATED[cookie_indexOf(name)];
    return cookie;
}

export function read_cookie_value_byName(name){
    let COOKIE_ARRAY = document.cookie.split(';');
    let COOKIE_VALUE_ARRAY = [];
    COOKIE_ARRAY.forEach(cookie => {
        let eqPos = cookie.indexOf('=');
        let value = eqPos > -1 ? cookie.substr(eqPos+1, cookie.length) : cookie;

        COOKIE_VALUE_ARRAY.push(value);
    });
    let value = COOKIE_VALUE_ARRAY[cookie_indexOf(name)];
    return value;
}

export function check_cookie_consents_byNameAndNumber(name, number) {
    try{ //try...catch, da die Funktion auch ausgeführt wird, wenn kein Cookie vorhanden ist - VALUE wäre i.d.F. undefined und die Funktion wirft ein Error. Da kein Cookie vorhanden ist, sollen die setting FALSE erhalten.
        let value = read_cookie_value_byName(name);
        let numberPos = value.indexOf(`${number}`);

        if (numberPos > -1){
            return true;
        }
        else{
            return false;
        }
    }catch{return null}
}

export function getCookieConsents(){
    let setConsents = {};
    const {
        REACT_APP_COOKIE_NAME,
        REACT_APP_COOKIE_CONSENT_ESSENTIAL_NUMB,
        REACT_APP_COOKIE_CONSENT_STATISTICS_NUMB,
        REACT_APP_COOKIE_CONSENT_MEDIA_NUMB,
        REACT_APP_COOKIE_CONSENT_STATISTICS_GAnalytics_NUMB,
        REACT_APP_COOKIE_CONSENT_MEDIA_FACEBOOK_NUMB,
        REACT_APP_COOKIE_CONSENT_MEDIA_GMAPS_NUMB
    } = process.env;

    setConsents = {
       checkEssential: check_cookie_consents_byNameAndNumber(REACT_APP_COOKIE_NAME, REACT_APP_COOKIE_CONSENT_ESSENTIAL_NUMB),
       checkStatistics: check_cookie_consents_byNameAndNumber(REACT_APP_COOKIE_NAME, REACT_APP_COOKIE_CONSENT_STATISTICS_NUMB),
       checkMedia: check_cookie_consents_byNameAndNumber(REACT_APP_COOKIE_NAME, REACT_APP_COOKIE_CONSENT_MEDIA_NUMB),
       checkStatisticsGA: check_cookie_consents_byNameAndNumber(REACT_APP_COOKIE_NAME, REACT_APP_COOKIE_CONSENT_STATISTICS_GAnalytics_NUMB),
       checkMediaGMaps: check_cookie_consents_byNameAndNumber(REACT_APP_COOKIE_NAME, REACT_APP_COOKIE_CONSENT_MEDIA_GMAPS_NUMB),
       checkMediaFB: check_cookie_consents_byNameAndNumber(REACT_APP_COOKIE_NAME, REACT_APP_COOKIE_CONSENT_MEDIA_FACEBOOK_NUMB)
    }

    return setConsents;
}

export function getCheckMedia(){
    const consents = getCookieConsents();
    return consents.checkMedia;
}
export function getCheckStatistics(){
    const consents = getCookieConsents();
    return consents.checkStatistics;
}
export function getCheckStatisticsGA(){
    const consents = getCookieConsents();
    return consents.checkStatisticsGA;
}
export function getCheckMediaGMaps(){
    const consents = getCookieConsents();
    return consents.checkMediaGMaps;
}
export function getCheckMediaFB(){
    const consents = getCookieConsents();
    return consents.checkMediaFB;
}